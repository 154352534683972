import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://bebf5fa4e8dd3e9c1c9ee63f0276a777@o4507102276878336.ingest.de.sentry.io/4507327601311824",
  debug: false,
  environment: "preview",
  release: "argos:2024-12-17 - main:preview",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});